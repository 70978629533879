/* eslint-disable formatjs/no-literal-string-in-jsx -- allow literal separator */
import { type ReactNode, useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { IconWarningSignFilled, IconNoteOutlined, IconLinkOutlined } from '@repo/monochrome-icons';
import { Tooltip } from '@repo/design-system-kit';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { Avatar } from 'qonto/react/components/suppliers/table/item/avatar';
import {
  FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY,
  FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY,
  GERMAN_E_INVOICES_ARTICLE_FOR_DE,
  GERMAN_E_INVOICES_ARTICLE_FOR_OTHERS,
} from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import styles from './supplier-name.strict-module.css';

interface CellContent {
  id: string;
  status: string;
  title: string;
  subtitle: string;
  missing: boolean;
  isEInvoice: boolean;
  hasSelfInvoice: boolean;
  hasDuplicates: boolean;
  isAttachmentNonFinancial: boolean;
  isCreditNote: boolean;
}

interface RelatedInvoice {
  invoice_number: string;
}

interface SupplierNameProps {
  cellContent: CellContent;
  firstEInvoiceId: string;
  isCreditNote?: boolean;
  relatedInvoices?: RelatedInvoice[];
}

export function SupplierName({
  cellContent,
  firstEInvoiceId,
  isCreditNote = false,
  relatedInvoices,
  ...props
}: SupplierNameProps): ReactNode {
  const { formatMessage } = useIntl();
  const zendeskLocalization = useEmberService('zendeskLocalization');
  const organizationManager = useEmberService('organizationManager');
  const localeManager = useEmberService('localeManager');

  const [isFirstFrenchEinvoiceTooltipDismissed, setIsFirstFrenchEinvoiceTooltipDismissed] =
    useState(false);
  const [hasDismissedTooltipForFirstGermanEInvoice, setHasDismissedTooltipForFirstGermanEInvoice] =
    useState(false);

  const organizationLegalCountry = organizationManager.organization.legalCountry;

  const shouldDisplayFirstEinvoiceTooltip = firstEInvoiceId === cellContent.id;
  const showTooltipOrEInvoiceLabel = cellContent.isEInvoice || cellContent.hasSelfInvoice;

  const einvoicingFAQLink = useMemo(() => {
    switch (organizationLegalCountry) {
      case 'FR':
        return zendeskLocalization.getLocalizedArticle('fr-einvoice');
      case 'DE':
        return localeManager.locale === 'de'
          ? GERMAN_E_INVOICES_ARTICLE_FOR_DE
          : GERMAN_E_INVOICES_ARTICLE_FOR_OTHERS;
      default:
        return '';
    }
  }, [organizationLegalCountry, zendeskLocalization, localeManager]);

  const toolTipTextContent = useMemo(() => {
    switch (organizationLegalCountry) {
      case 'FR':
        return {
          title: formatMessage({ id: 'supplier-invoices.first-einvoice-card.title' }),
          description: formatMessage({ id: 'supplier-invoices.first-einvoice-card.subtitle.body' }),
          linkText: formatMessage({
            id: 'supplier-invoices.instructional-tooltip.einvoicing-disclaimer.link',
          }),
          faqSource: '',
        };
      case 'DE':
        return {
          title: formatMessage({
            id: 'supplier-invoices.table.instructional-tooltip.first-einvoice.title',
          }),
          description: formatMessage({
            id: 'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.body',
          }),
          linkText: formatMessage({
            id: 'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.link',
          }),
          faqSource: 'first-einvoice',
        };
      default:
        return {
          title: '',
          description: '',
        };
    }
  }, [formatMessage, organizationLegalCountry]);

  const isInstructionalToolTipDismissed = useMemo(() => {
    if (organizationLegalCountry === 'FR') {
      return isFirstFrenchEinvoiceTooltipDismissed;
    } else if (organizationLegalCountry === 'DE') {
      return hasDismissedTooltipForFirstGermanEInvoice;
    }
    return false;
  }, [
    organizationLegalCountry,
    isFirstFrenchEinvoiceTooltipDismissed,
    hasDismissedTooltipForFirstGermanEInvoice,
  ]);

  const tooltipLinkedInvoicesMessage = useMemo(() => {
    const invoiceNumber = relatedInvoices?.[0]?.invoice_number;
    if (isCreditNote && invoiceNumber) {
      return formatMessage(
        {
          id: 'supplier-invoices.invoices-list.invoices.linked-invoice',
        },
        { invoiceNumber }
      );
    }

    if (isCreditNote) {
      return formatMessage({
        id: 'supplier-invoices.invoices-list.invoices.no-number-linked-invoice',
      });
    }

    return formatMessage(
      {
        id: 'supplier-invoices.invoices-list.invoices.linked-credit-note',
      },
      { amount: relatedInvoices?.length }
    );
  }, [relatedInvoices, isCreditNote, formatMessage]);

  const tooltipWarningDuplicateMessage = cellContent.isCreditNote
    ? formatMessage({ id: 'supplier-invoices.table.preview.credit-notes.duplicate-tooltip' })
    : formatMessage({ id: 'supplier-invoices.table.preview.duplicate-tooltip' });

  const handleFirstFrenchEinvoiceTooltipClose = (): void => {
    setIsFirstFrenchEinvoiceTooltipDismissed(true);
    safeLocalStorage.setItem(FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY, 'true');
  };

  const handleCloseFirstGermanEInvoiceTooltip = (): void => {
    setHasDismissedTooltipForFirstGermanEInvoice(true);
    safeLocalStorage.setItem(FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY, 'true');
  };

  const handleInstructionalTooltipClosure = (): void => {
    if (organizationLegalCountry === 'FR') {
      handleFirstFrenchEinvoiceTooltipClose();
    } else if (organizationLegalCountry === 'DE') {
      handleCloseFirstGermanEInvoiceTooltip();
    }
  };

  return (
    <div className={cx('body-1', styles.cell)} {...props}>
      <div className={styles['supplier-container']}>
        <Avatar name={cellContent.title} />
        <div className={styles.overflow} data-test-cell-label-container>
          <div className={styles['cell-label-container']}>
            <span
              className={cx(
                styles['cell-label'],
                styles.overflow,
                cellContent.missing && styles.missing
              )}
            >
              {cellContent.missing ? (
                <FormattedMessage id="supplier-invoices.table.preview.missing-information" />
              ) : (
                cellContent.title
              )}

              {shouldDisplayFirstEinvoiceTooltip ? (
                <InstructionalTooltip
                  data-test-first-fr-einvoice-instructional-tooltip
                  description={toolTipTextContent.description}
                  faqLinkEventName="Einvoicing-FAQ_link_clicked"
                  faqLinkEventOrigin="supplier-invoice"
                  faqLinkText={toolTipTextContent.linkText}
                  faqLinkUrl={einvoicingFAQLink}
                  faqSource={toolTipTextContent.faqSource}
                  isDismissed={isInstructionalToolTipDismissed}
                  isNew
                  lottieSrc="/lotties/e-invoicing-discovery/e-invoicing-discovery.json"
                  onClose={handleInstructionalTooltipClosure}
                  placement="lower-right"
                  title={toolTipTextContent.title}
                />
              ) : null}
            </span>
          </div>
          {cellContent.subtitle ? (
            <div className={cx(styles['flex-label'], styles['sub-label'], styles.overflow)}>
              {cellContent.isCreditNote ? (
                <span data-test-credit-note-label>
                  <FormattedMessage id="supplier-invoices.credit-note.label" />
                </span>
              ) : null}
              <span
                className={cx(
                  styles.overflow,
                  cellContent.isCreditNote && styles['with-separator']
                )}
                data-test-cell-subtitle
              >
                {cellContent.subtitle}
              </span>
              {cellContent.hasDuplicates || cellContent.isAttachmentNonFinancial ? (
                <Tooltip
                  label={
                    cellContent.hasDuplicates
                      ? tooltipWarningDuplicateMessage
                      : formatMessage({
                          id: 'supplier-invoices.table.preview.non-financial-doc-tooltip',
                        })
                  }
                  needsButton
                  placement="top"
                  tooltipTestId="warning"
                >
                  <IconWarningSignFilled
                    aria-hidden="true"
                    className={cx('ml-8', styles['file-name-warning'])}
                    data-test-file-name-icon="warning"
                  />
                </Tooltip>
              ) : null}
              {relatedInvoices?.length ? (
                <div>
                  <span className="ml-8">·</span>
                  <Tooltip
                    label={tooltipLinkedInvoicesMessage}
                    needsButton
                    placement="top"
                    tooltipTestId="linked-invoices"
                  >
                    <IconLinkOutlined
                      aria-hidden="true"
                      className={cx('ml-8', styles['linked-invoices-icon'])}
                      data-test-file-name-icon="link_outlined"
                    />
                  </Tooltip>
                </div>
              ) : null}
              {showTooltipOrEInvoiceLabel ? (
                <span className={styles['with-separator']}>
                  {cellContent.hasSelfInvoice ? (
                    <Tooltip
                      label={formatMessage({
                        id: 'supplier-invoices.table.preview.self-invoices.tooltip',
                      })}
                      needsButton
                      placement="top"
                      tooltipTestId="self-invoice"
                    >
                      <IconNoteOutlined
                        aria-hidden="true"
                        className={styles['self-invoice-icon']}
                        data-test-self-invoice-icon=""
                      />
                    </Tooltip>
                  ) : (
                    <FormattedMessage id="supplier-invoices.table.preview.self-invoices.label" />
                  )}
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
